import axios from 'axios'
import store from '../store'
import router from '@/router'
import Const from '@/utils/const'
import Cookies from 'js-cookie'
// 创建axios实例
const instance = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  // baseURL: 'http://localhost:8888/', // api 的 base_url
  // baseURL: 'http://huawei:8888/', // api 的 base_url
  // baseURL: '/mockjson/', // api 的 base_url
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: Const.timeout // 请求超时时间
})
// request拦截器
instance.interceptors.request.use(
  config => {
    // console.log('config', config)
    // console.log('url', config.url)
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
instance.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    // 兼容blob下载出错json提示
    if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
      const reader = new FileReader()
      reader.readAsText(error.response.data, 'utf-8')
      reader.onload = function () {
        const errorMsg = JSON.parse(reader.result).message
        // Notification.error({
        //   title: errorMsg,
        //   duration: 5000
        // })
        console.log(errorMsg)
      }
    } else {
      let code = 0
      try {
        code = error.response.data.status
      } catch (e) {
        if (error.toString().indexOf('Error: timeout') !== -1) {
          //   Notification.error({
          //     title: '网络请求超时',
          //     duration: 5000
          //   })
          console.log('网络请求超时')
          return Promise.reject(error)
        }
      }
      console.log('接口请求失败')
      console.log(code, error.response.data.message)
      if (code) {
        if (code === 401) {
          store.dispatch('LogOut').then(() => {
            // 用户登录界面提示
            Cookies.set('point', 401)
            location.reload()
          })
        } else if (code === 403) {
          router.push('/403')
        } else {
          const errorMsg = error.response.data.message
          if (errorMsg !== undefined) {
            // Notification.error({
            //   title: errorMsg,
            //   duration: 5000
            // })
            console.log(errorMsg)
          }
        }
      } else {
        // Notification.error({
        //   title: '接口请求失败',
        //   duration: 5000
        // })
        console.log('接口请求失败')
      }
    }
    return Promise.reject(error)
  }
)
export default instance
