import request from '@/utils/request'

//102 搜索
export function search(keyword, cityName) {
  let data = ''.concat('CMD=', '102').concat('&KEYWORD=', keyword).concat('&CITYNAME=', cityName)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}

//103 线路详情
export function lineDetails(lineName, direction, cityName) {
  let data = ''.concat('CMD=', '103').concat('&LINENAME=', lineName).concat('&DIRECTION=', direction).concat('&CITYNAME=', cityName)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}

//104 实时查车
export function realtime(lineName, direction, cityName) {
  let data = ''.concat('CMD=', '104').concat('&LINENAME=', lineName).concat('&DIRECTION=', direction).concat('&CITYNAME=', cityName)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}

//105
export function nearStations105(cityName, lat, lng) {
  let data = ''.concat('CMD=', '106').concat('&CITYNAME=', cityName).concat('&LAT=', lat).concat('&LNG=', lng)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}

//106 附近站点
export function nearStations(cityName, lat, lng) {
  let data = ''.concat('CMD=', '106').concat('&CITYNAME=', cityName).concat('&LAT=', lat).concat('&LNG=', lng)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}



//114 经过站点的线路列表
export function linesByStationName(cityName, stationName, lat, lng, all) {
  let data = ''.concat('CMD=', '114').concat('&CITYNAME=', cityName).concat('&STATIONNAME=', stationName).concat('&MYLAT=', lat).concat('&MYLNG=', lng).concat('&ALL=', all)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}

//115 广告-没用
export function ads(cityName) {
  let data = ''.concat('CMD=', '115').concat('&CITYNAME=', cityName)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}

//119 公交发车时刻表
export function route(routeId, direction, cityName) {
  let data = ''.concat('CMD=', '119').concat('&ROUTEID=', routeId).concat('&DIRECTION=', direction).concat('&CITYNAME=', cityName)
  return request({
    url: 'WxBusServer/ApiData.do',
    method: 'post',
    data
  })
}
